/* Styles specific to the FFF login card to avoid conflicts with other login pages */
.fff-login-card {
	width: 50%; /* Adjust the width to make it more compact */
	height: calc(100vh - 100px); /* To fit between header and footer */
	display: flex;
	flex-direction: column;
	justify-content: flex-start; /* Start at the top */
	align-items: center;
	background-color: transparent;
	padding: 2px;
	margin: 0 auto; /* Center the card */
}

.fff-login-title {
	font-size: 2.5rem;
	margin-bottom: 30px;
	text-align: center;
	color: #fff;
	font-weight: bold;
}

.fff-login-form {
	border: 2px solid #d4af37;
	background-color: #4f0624;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 50%;
	margin-bottom: 10px;
	padding: 20px;
}

.fff-login-input {
	width: 70%;
	padding: 13px;
	margin-bottom: 20px;
	font-size: 1.2rem;
	border-radius: 15px;
	background-color: #6a2a44;
	color: #fff;
	border: 2px solid transparent;
	transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Added transition for box-shadow */
}

/* Hover and Focus State for Input */
.fff-login-input:hover,
.fff-login-input:focus {
	border-color: #ffd700; /* Gold border on hover and focus */
	outline: none; /* Remove default outline */
	box-shadow: 0 0 5px #ffd700; /* Optional: Glow effect on focus */
}

.fff-login-head {
	color: white;
	margin-top: 0px;
}

.fff-login-btn {
	background-color: #d1b200;
	padding: 10px; /* Increased padding */
	border: none;
	border-radius: 15px; /* Rounded corners */
	color: #3b0b24;
	font-weight: bold;
	cursor: pointer;
	width: 100px;
	transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
	font-size: 1.1rem; /* Slightly larger font */
}

.fff-login-btn:hover {
	background-color: #e9c600;
}

.fff-login-images {
	display: flex;
	justify-content: center;
	gap: 20px; /* Reduce gap between images */
	width: 100%;
	margin-top: 10px;
}

.fff-login-image {
	width: 220px; /* Smaller image size */
	height: auto;
	object-fit: contain;
}
.fff-login-input::placeholder {
	color: #d0d0d0;
	opacity: 1;
}

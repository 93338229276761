::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 10px;
}

::-webkit-scrollbar-track {
	background: #4f0624;
	border-radius: 10px;
}

/* Header styles */
.header-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	max-height: 1%;
}

.header-logo {
	width: 60px; /* Logo size */
	height: auto; /* Maintain aspect ratio */
	margin-right: 10px; /* Space between logo and title */
}

.header-login {
	font-size: 2.5rem;
	background: linear-gradient(
		to bottom,
		rgba(255, 215, 0, 0.2) 0%,
		rgba(255, 215, 0, 1) 50%,
		rgba(255, 215, 0, 0.2) 100%
	);
	-webkit-background-clip: text; /* For Safari */
	-webkit-text-fill-color: transparent; /* Makes the text color transparent to show the gradient */
	margin-top: 21px;
}

/* Page container */
.page-container {
	display: flex;
	flex-direction: column;
	height: 82vh;
	justify-content: center;
}

/* Content section */
.content {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin-bottom: 2rem;
	overflow-y: auto;
}

/* Form section on the left */
.form-section {
	display: flex;
	min-width: 50%;
	justify-content: center;
}

.form-box {
	padding: 20px;
	border-radius: 15px;
	color: white;
	max-width: 500px;
	width: 100%;
	height: auto;
	/* min-height: calc(100vh - 210px); */
	overflow: hidden;
}
.toggle-button {
	cursor: pointer;
	color: #ffd700;
	background-color: transparent;
	border: none;
	font-size: 1.2rem;
}
.form-footer {
	font-size: 1.2rem;
}

/* Title and subtitle */
.form-title {
	font-size: 2.5rem;
	margin: 2px;
}

.form-subtitle {
	font-size: 2rem;
	margin: 2px;
}
.form-subtitle-l {
	font-size: 2rem;
	margin: 2px;
}

.page-container span {
	color: #ffd700;
}
/* Form layout */
.form {
	display: flex;
	flex-direction: column;
}

.input-row {
	display: flex;
	align-items: center; /* Center items vertically */
	/* margin-bottom: 12px; Minimal vertical margin */
}

.input-field {
	flex: 1; /* Allow fields to grow equally */
	display: flex; /* Flexbox for input and icon */
	align-items: center; /* Center items vertically */
	margin-right: 50px; /* Space between fields */
	background-color: transparent;
	flex-direction: column;
	position: relative; /* Enable positioning for child elements */
	margin-bottom: 20px;
}

.input-placeholder {
	font-size: 0.9rem;
	text-align: left;
	max-width: fit-content;
	position: absolute; /* Positioning the placeholder */
	bottom: 11px; /* Adjust this value to position it below the input */
	left: 10px; /* Adjust as needed */
	z-index: 1; /* Ensure it appears above other elements */
	color: rgb(255, 255, 255); /* Example color, adjust as needed */
	font-weight: 500;
	transition: transform 0.3s ease, font-size 0.3s ease; /* Smooth transition */
}

.input-field:last-child {
	margin-right: 0; /* Remove margin for last field */
}

.input-field i {
	margin-right: 1px; /* Space between icon and input */
}
.input-field select {
	min-width: 110%;
}
/* Input styles */
.input-field input,
.input-field select {
	width: 99%;
	padding: 10px; /* Reduced padding for height */
	padding-left: 10px; /* Space for icon */
	background-color: #6a2a44; /* Maroon background for better blending */
	border: 2px solid transparent; /* Transparent border for hover effect */
	border-radius: 15px; /* Rounded corners */
	color: white;
	transition: border-color 0.3s ease; /* Smooth transition */
}
.input-field input::placeholder,
.input-field select::placeholder {
	color: #d3d3d3; /* Change this to your desired color */
	opacity: 1; /* Optional: Set opacity to 1 for full visibility */
}

/* Transition for placeholder */
.input-field input:focus + .input-placeholder,
.input-field input:not(:placeholder-shown) + .input-placeholder {
	transform: translateY(-18px); /* Move the placeholder up */
	background-color: #6a2a44;
	font-size: 0.8rem; /* Smaller font size */
	color: #ffd700; /* Change color if desired */
	padding: 0 5px;
	border-radius: 20px;
}

.input-field input:focus,
.input-field select:focus {
	outline: none; /* Remove default outline */
	border-color: #ffd700; /* Gold border on focus */
}

.input-field input:hover,
.input-field select:hover {
	border-color: #ffd700; /* Gold border on hover */
}

/* Error message */
.input-field .error {
	color: #ff4d4d; /* Brighter red for better visibility */
	font-size: 0.8rem;
	margin-top: 1px;
}

/* Long input */
.long-input {
	width: 100%; /* Full width for long input */
}

/* Submit button */
.submit-button {
	background-color: #d1b200;
	padding: 12px; /* Increased padding */
	border: none;
	border-radius: 15px; /* Rounded corners */
	color: #3b0b24;
	font-weight: bold;
	cursor: pointer;
	width: 104%;
	margin: 20px auto 0;
	/* margin: 20px auto 30px; */
	margin-left: -10px;
	transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
	font-size: 1.1rem; /* Slightly larger font */
}

.submit-button-l {
	width: 93.5%;
}

.submit-button:hover {
	background-color: #e9c600; /* Darker gold on hover */
}

/* Images section on the right */
.images-section {
	max-width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-left: 20px;
}

/* Adjusting the stacked image to ensure it doesn't overflow */
.stacked-image {
	/* margin-bottom: 10px; Reduced margin */
	width: 30%; /* Image size */
	height: auto;
}
.stacked-image-3 {
	width: 38%;
}
/* Footer styles */
.footer {
	text-align: center;
	background-color: rgba(0, 0, 0, 0.3) !important; /* Optional styling */
	padding: 10px;
	box-sizing: border-box; /* Ensure padding doesn't affect width */
}

/* Center footer text */
.footer p {
	margin: 0; /* Ensure no default margins are applied */
	color: rgb(255, 0, 0);
}

/* Responsive styles */
@media (max-width: 768px) {
	.content {
		flex-direction: column; /* Stack content on small screens */
		align-items: center; /* Center items */
		height: 100vh;
	}
	.submit-button,
	.submit-button-l {
		margin-bottom: 30px;
	}
	.form-section,
	.images-section {
		width: 100%; /* Full width on small screens */
	}

	.stacked-image {
		width: 80%; /* Image size on small screens */
	}

	.input-field {
		margin-right: 0; /* Remove right margin in vertical layout */
	}
}

/* Adjusting the height of the form */

/* General styles for mobile and tablet responsiveness */
@media (max-width: 768px) {
	/* Tablet and smaller screen styles */
	html,
	body {
		font-size: 14px; /* Scale down font size */
		background-size: cover;
	}
	.page-container {
		padding: 0 10px; /* Add padding to prevent content from touching screen edges */
	}
	.submit-button,
	.submit-button-l {
		margin-bottom: 30px;
	}
	.header-container {
		margin-bottom: 10px;
	}

	.header-login {
		font-size: 2rem; /* Smaller header font */
		text-align: center;
	}

	.content {
		flex-direction: column; /* Stack form and images vertically */
		margin-bottom: 1rem;
		max-height: 90%;
	}

	.form-section {
		width: 80%; /* Full width on smaller screens */
		margin-bottom: 20px; /* Add margin for spacing */
	}

	.images-section {
		width: 100%; /* Full width on smaller screens */
		padding-left: 0; /* Remove left padding */
	}

	.stacked-image {
		width: 70%; /* Resize images on smaller screens */
		margin-bottom: 10px;
	}

	.form-box {
		width: 100%; /* Make form full-width */
		max-width: 100%; /* Remove max-width for smaller screens */
		padding: 15px; /* Reduce padding */
		border-radius: 10px; /* Adjust rounding */
	}

	.form-title {
		font-size: 2rem; /* Scale down title */
		text-align: center;
	}

	.form-subtitle,
	.form-subtitle-l {
		/* font-size: 1.5rem; */
		text-align: center;
	}
	.form-footer {
		text-align: center;
	}

	.input-field {
		flex-direction: column; /* Stack input and placeholder vertically */
		width: 100%;
		margin-right: 0; /* Remove margin for full-width fields */
	}
	.input-field select {
		min-width: 103%;
	}
	.input-row {
		flex-direction: column; /* Stack input fields */
	}

	.submit-button,
	.submit-button-l {
		width: 100%; /* Make button full-width */
		margin: 20px 0; /* Adjust margins */
	}

	.footer {
		padding: 5px;
	}
	.footer p {
		font-size: 0.9rem; /* Adjust footer text size */
	}
}

/* Specific adjustments for small mobile devices */
@media (max-width: 480px) {
	.header-login {
		font-size: 1.6rem; /* Reduce header size for mobile */
	}

	.form-title {
		font-size: 1.8rem; /* Reduce title size for mobile */
	}
	.form-box {
		padding: 10px; /* Reduce padding */
	}

	.input-field select {
		min-width: 106%;
	}
	.input-field .long-input {
		margin: 50px;
	}
	.submit-button,
	.submit-button-l {
		margin-bottom: 30px;
	}
	.submit-button,
	.submit-button-l {
		width: 100%; /* Full width button */
		font-size: 1rem; /* Smaller font size */
	}
	.stacked-image {
		width: 80%; /* Resize images */
	}
	.images-section {
		padding-left: 0;
	}
	.form-footer {
		font-size: 1rem;
		text-align: center;
	}
}
/* For landscape orientation on small screens (width <= 500px) */
@media screen and (max-height: 500px) and (orientation: landscape) {
	html,
	body {
		height: 100%;
		overflow-y: auto; /* Ensure scrolling when needed */
	}

	.header-container {
		position: relative; /* Keep header static */
		z-index: 10; /* Ensure it stays above other elements */
		height: auto;
		margin-bottom: 10px; /* Space it from the form */
	}
	.submit-button,
	.submit-button-l {
		margin-bottom: 30px;
	}
	.content {
		flex-direction: row; /* Align content horizontally */
		align-items: flex-start; /* Align items to the top */
		justify-content: space-between; /* Space between form and images */
		height: auto; /* Let the height adjust based on content */
		overflow-y: auto; /* Allow vertical scrolling if necessary */
		padding-bottom: 20px; /* Add some space for scrolling comfort */
	}

	.form-section {
		flex-basis: 50%; /* Split the screen in half */
		display: flex;
		justify-content: center; /* Center the form */
		align-items: flex-start; /* Keep the form aligned at the top */
		height: auto;
	}

	.form-box {
		width: 100%; /* Full width for the form */
		max-width: 500px;
		padding: 20px;
		border-radius: 15px;
		box-sizing: border-box;
		min-height: 100vh; /* Ensure form fills available height */
		overflow-y: auto; /* Enable scrolling within the form if needed */
	}

	.images-section {
		flex-basis: 50%; /* Split the screen */
		height: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px; /* Ensure images have padding */
		margin-bottom: 15px;
	}

	.stacked-image,
	.stacked-image-3 {
		max-width: 110%;
		height: auto;
	}
}

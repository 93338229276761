.home-container {
	display: flex;
	flex-direction: column;
	min-height: 80vh; /* Full viewport height */
	padding: 20px;
	text-align: center;
	box-sizing: border-box; /* Ensure padding is included in the container size */
	overflow-y: auto;
}

.player-info {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	color: white;
	margin: 20px 0;
	font-size: 18px;
	width: 100%;
}

.info-item {
	position: relative;
	padding: 10px;
	text-align: center;
	flex: 1;
	min-width: 150px;
	max-width: 300px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.vertical-line {
	width: 2px; /* Line thickness */
	background-color: white; /* Line color */
	height: 60px; /* Adjust height as needed */
	display: block; /* Ensure it's displayed */
}

/* Text styles for round text */
.round-buttons-container .hexagon-container .round-text {
	fill: white;
	font-size: 22px;
	font-weight: bold;
}

/* Text styles for number */
.round-buttons-container .hexagon-container .number-text {
	fill: white;
	font-size: 45px;
	font-weight: bold;
}

.round-buttons-container .hexagon-container .instruction-text {
	fill: white;
	font-size: 18px;
	font-weight: bold;
}

.round-buttons-container .hexagon-container:hover polygon {
	fill: url(#gradient);
}

.round-buttons-container .hexagon-container:active {
	transform: scale(0.95);
}

.hexagon-container:hover .round-text,
.hexagon-container:hover .number-text {
	fill: #4f0624; /* Red text color on hover */
}

.info-title {
	font-weight: bold;
	margin-bottom: 5px;
}

.info-content {
	font-size: 15px;
}

.game-status-box {
	border: 2px solid white;
	border-radius: 50px;
	margin: 20px auto;
	margin-top: 5vw;
	width: 25vw;
	font-size: 1.7rem;
	line-height: 52px;
	height: 50px;
	background-color: #4f0624;
	color: white;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
}

@media (max-width: 700px) {
	.game-status-box {
		width: 70vw; /* Increase width on smaller screens */
		font-size: 5vw; /* Larger font size for better readability */
		height: 7vh; /* Adjust height for smaller screens */
	}

	/* Hide the original player-info on mobile */
	.player-info {
		display: none; /* Hide for mobile */
	}

	.mobile-player-info {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%; /* Full width for mobile layout */
		color: white;
	}

	.mobile-name-item {
		width: 100%; /* Full width for the name */
		text-align: center; /* Center text */
		margin-bottom: 8px;
	}

	.referral-points-container {
		display: flex; /* Side by side for referral code and points */
		justify-content: space-between; /* Space them evenly */
		width: 100%; /* Full width */
	}

	.info-item {
		margin: 0 10px; /* Margin for spacing */
		flex: 1; /* Equal width for referral and points */
		min-width: 0; /* Prevents overflow */
	}

	.vertical-line {
		height: 40px; /* Adjust height for mobile view */
		margin: 10px 0; /* Space around the line */
	}
}

@media (min-width: 701px) {
	.mobile-player-info {
		display: none; /* Hide mobile layout for larger screens */
	}
}

@media (orientation: landscape) and (max-height: 540px) {
	.game-status-box {
		width: 40vw; /* Adjust width for landscape on smaller screens */
		font-size: 6vh; /* Ensure font size is readable */
	}

	.home-container {
		overflow-y: auto; /* Enable vertical scrolling */
		max-height: 90vh; /* Prevent overflow beyond viewport */
		padding-bottom: 20px; /* Add padding to avoid content sticking to the bottom */
	}

	.home-container::-webkit-scrollbar {
		width: 8px;
	}

	.home-container::-webkit-scrollbar-thumb {
		background-color: rgba(255, 255, 255, 0.5);
		border-radius: 10px;
	}

	.home-container::-webkit-scrollbar-track {
		background: #4f0624;
	}
}

.no-schedule {
	background-color: #4f0624;
}

.scheduled {
	background-color: #4f0624;
}

.round-buttons-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 30px;
	margin: 70px auto;
	font-weight: 200;
	width: 100%;
	height: 10%;
}

/* Additional styles for round buttons */
.round-button {
	border: none;
	border-radius: 50px;
	padding: 10px 20px;
	background-color: #4f0624;
	color: white;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.round-button:hover {
	background-color: #6b0c3c; /* Darker shade on hover */
}

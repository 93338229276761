.footer-container {
  width: 65vw; /* 3/4 of the screen for large screens */
  height: 300px; /* Increased height */
  background-color: #4F0624; /* Adjust background color */
  position: fixed;
  bottom: -265px; /* Push most of the footer down */
  left: 50%;
  transform: translateX(-50%);
  border-radius: 80px; /* Curved edges at the top */
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align content at the top */
  overflow: hidden; /* Prevent scrolling to reveal hidden part */
  border: 2px solid #b8860b; /* Dull gold border */
  z-index: 100;
}

.footer-content {
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  position: absolute; /* Absolute positioning */
  top: 6px; /* Move content down without increasing height */
}

.circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  font-weight: bold;
  font-size: 12px;
}

/* Media query for small screens */
@media (max-width: 1024px) {
  .footer-container {
      width: 100%; /* Full width */
      height: auto; /* Auto height to fit content */
      bottom: 0; /* Position at the bottom */
      border-radius: 0; /* Remove curved edges */
      padding: 10px; /* Optional: Add some padding */
  }

  .footer-content {
      position: static; /* Reset positioning */
      text-align: center; /* Center text */
  }
}

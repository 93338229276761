.leaderboard-overlay {
	position: fixed;
	top: 94px;
	left: 0;
	right: 0;
	bottom: 40px;
	background-color: rgba(79, 6, 36, 0.8);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	animation: fadeIn 0.3s ease-in-out;
}

.leaderboard-content {
	width: 100%;
	height: 100%;
	max-width: 1300px;
	background-color: rgb(240, 240, 240);
	border-radius: 10px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
	position: relative;
	animation: slideInUp 0.3s ease-in-out;
}

.error-message {
	text-align: center;
	font-size: 18px;
	font-weight: bold;
	margin-top: 10%;
}

.close-leaderboard {
	position: absolute;
	top: 10px;
	right: 15px;
	background: none;
	border: none;
	font-size: 40px;
	color: #646152;
	cursor: pointer;
	transition: color 0.3s ease; /* Smooth transition for hover effect */
}
.close-leaderboard:hover {
	color: #28241a;
}

.heading-leaderboard {
	color: #4f0624;
	text-align: center;
	margin-bottom: 20px;
}

.leaderboard-list {
	flex: 1;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: minmax(30px, auto);
	gap: 10px;
	overflow-y: auto;
	padding-right: 10px;
}

.leaderboard-item {
	background-color: rgba(198, 198, 198, 0.7);
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
}

.leaderboard-item span {
	color: #4f0624;
	font-weight: bold;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
	.leaderboard-list {
		grid-template-columns: 1fr;
	}
}

/* Style the scrollbar */
.leaderboard-list::-webkit-scrollbar {
	width: 8px;
}

.leaderboard-list::-webkit-scrollbar-thumb {
	background-color: #4f0624;
	border-radius: 10px;
}

.leaderboard-list::-webkit-scrollbar-track {
	background: rgba(255, 255, 255, 0.5);
}
@media screen and (max-height: 500px) and (orientation: landscape) {
	
}
/* Overlay with fade-in animation */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  animation: fadeIn 0.3s ease-in-out;
  overflow-y: auto; /* Ensure scrolling if content is too large */
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

/* Instruction card with improved shadow and padding */
.instruction-card {
  background: white;
  border-radius: 10px;
  padding: 30px;
  max-width: 580px;
  width: 90%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  position: relative;
  text-align: left;
  border: 1px solid #ddd;
  animation: slideInUp 0.3s ease-in-out;
  max-height: 90vh; /* Limit height for shorter screens */
  overflow-y: auto; /* Enable scrolling if the content exceeds height */
}

@keyframes slideInUp {
  from {
      transform: translateY(20px);
  }
  to {
      transform: translateY(0);
  }
}

/* Title styling */
.heading-instruction {
  margin-bottom: 15px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
}

/* Unordered list with more polished layout */
ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

/* Custom list item styling */
li {
  margin-bottom: 15px;
  padding-left: 1.8em;
  position: relative;
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

/* Custom bullet point */
li:before {
  content: "❖";
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.2em;
  color: #4f0624;
}

/* Close button */
.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #999;
  transition: color 0.3s;
}

.close-button:hover {
  color: #333;
}

/* Agree button with enhanced styling */
.agree-button {
  background-color: #4f0624;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
}

.agree-button:hover {
  background-color: #6e0e32;
}

/* Responsive styles */
@media (max-width: 768px) {
  .instruction-card {
      padding: 20px;
      max-width: 90%;
      max-height: 80vh; /* Reduce height for smaller devices */
  }

  h2 {
      font-size: 1.5rem;
  }

  li {
      font-size: 0.9rem;
  }

  .agree-button {
      font-size: 1rem;
  }
}

/* Shorter height responsiveness */
@media (max-height: 500px) {
  .instruction-card {
    padding: 15px;
    max-height: 80vh; /* Adjust to fit shorter height screens */
    overflow-y: auto; /* Enable vertical scrolling if needed */
  }

  .agree-button {
    padding: 10px 15px;
    font-size: 1rem;
  }
}

/* Landscape orientation adjustments */
@media (orientation: landscape) and (max-width: 820px) {
  /* Adjust the instruction card */
  .instruction-card {
    position: absolute;
    top: 70px; /* Just below the header */
    bottom: 60px; /* Just above the footer */
    width: 98%; /* Full width */
    height: calc(100vh - 115px); /* Total height minus header and footer */
    max-width: none; /* Disable the max-width to make it full width */
    padding: 20px; /* Adjust padding for a landscape view */
    box-sizing: border-box; /* Ensure padding doesn't overflow */
    overflow-y: auto; /* Allow scrolling if content overflows */
  }
}

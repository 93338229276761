html,
body {
	margin: 0;
	padding: 0;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	height: 100%;
	background: url("../src/assets/images/background.jpg") center;
	background-size: cover;
	overflow: hidden;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media (max-width: 786px) {
	.grecaptcha-badge {
		margin-bottom: 10%;
	}
}

@media (max-height: 600px) and (orientation: landscape) {
	.grecaptcha-badge {
		margin-bottom: 0;
	}
}

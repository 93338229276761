.result-card {
	display: flex;
	flex-direction: column;
	background-color: #4f0624;
	color: #4f0624;
	border: 3px solid #4f0624;
	border-radius: 10px;
	width: calc(100% - 500px);
	height: calc(100vh - 130px);
	margin: 0 auto;
	position: relative;
	box-sizing: border-box;
	overflow: hidden;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
	animation: slideInUp 0.3s ease-in-out;
}

.result-card-header {
	padding: 10px 30px 5px;
	text-align: center;
	background-color: #4f0624;
}

.result-card h2 {
	margin-top: 0;
	margin-bottom: 5px;
	font-size: 40px;
	color: #ffffff;
}

.points-coupon {
	font-size: 30px;
	margin-bottom: 10px;
	color: #ffffff;
}

.points-number {
	font-weight: bold;
	color: #ffffff;
}

.home-button {
	background-color: #d1b200;
	border: none;
	border-radius: 5px; /* Adjust the border radius for the button */
	padding: 10px 20px; /* Adjust padding for a better appearance */
	color: white;
	font-size: 20px; /* Adjust font size */
	cursor: pointer;
	margin-top: 10px; /* Add some margin to separate from the points text */
	transition: background-color 0.3s ease;
}

.home-button:hover {
	background-color: #e9c600;
}

.download-button {
	background-color: #d1b200;
	border: none;
	margin-left: 10px;
	border-radius: 5px; /* Adjust the border radius for the button */
	padding: 10px 20px; /* Adjust padding for a better appearance */
	color: white;
	font-size: 20px; /* Adjust font size */
	cursor: pointer;
	margin-top: 10px; /* Add some margin to separate from the points text */
	transition: background-color 0.3s ease;
}

.download-button:hover {
	background-color: #e9c600;
}

.result-card-content {
	flex-grow: 1;
	overflow-y: auto;
	padding: 0 30px 30px;
}

.image-gallery {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 20px;
	margin-top: 20px;
	width: 100%;
}

.result-image {
	width: auto;
	height: auto;
	border-radius: 10px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.image-gallery.one-image .result-image {
	max-width: 50%;
}

.image-gallery.two-images .result-image {
	max-width: 48%;
}

.image-gallery.three-images .result-image,
.image-gallery.four-to-six-images .result-image {
	max-width: 31%;
}

.result-card-content::-webkit-scrollbar {
	width: 8px;
}

.result-card-content::-webkit-scrollbar-thumb {
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 10px;
}

.result-card-content::-webkit-scrollbar-track {
	background: #4f0624;
}

/* Media queries to ensure responsiveness */
@media (max-width: 1024px) {
	.result-card {
		width: calc(100% - 40px);
	}
}

@media (max-width: 768px) {
	.result-card h2 {
		font-size: 30px;
	}
	.points-coupon {
		font-size: 25px;
	}
	.home-button {
		font-size: 18px; /* Adjust font size for smaller screens */
	}
	.image-gallery {
		gap: 15px;
	}
	.result-image {
		max-width: 45% !important;
	}
}

@media (max-width: 480px) {
	.result-card {
		width: 100%;
		height: calc(100vh - 100px);
	}
	.result-card-header,
	.result-card-content {
		padding: 10px;
	}
	.result-card h2 {
		font-size: 25px;
	}
	.points-coupon {
		font-size: 22px;
	}
	.result-image {
		max-width: 100% !important;
	}
}

/* Add styles for landscape orientation */
@media (max-height: 500px) and (orientation: landscape) {
	.result-card h2 {
		font-size: 15px; /* Smaller font size for h2 */
	}
	.points-coupon {
		font-size: 11px; /* Smaller font size for points coupon */
	}
	.home-button,
	.download-button {
		font-size: 12px; /* Smaller font size for buttons */
	}
}

.fff-container * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Apply the width, height, and overflow to .fff-container */
.fff-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
    box-sizing: border-box;
    color: white;
    min-height: 100vh;
    width: 100%;      /* Replaces body, html width */
    height: 100vh;    /* Replaces body, html height */
    overflow: hidden; /* Replaces body, html overflow */
}

.order-submit {
    margin-top: 10px;
    display: flex; /* Enables Flexbox */
    flex-direction: row; /* Aligns items in a row */
    justify-content: space-evenly; /* Centers the items horizontally */
    align-items: center; /* Centers the items vertically */
    gap: 10px; /* Adds some space between the items */
    width: 100%; /* Ensures the div takes the full width */
    padding: 10px; /* Optional: Adds some padding around the content */
    box-sizing: border-box;
}

.fff-heading {
    font-size: 36px;
    font-weight: bold;
    margin-top: 0;
}

.fff-container .timer-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.fff-container .timer-wrapper {
    position: relative;
    width: 100px;
    height: 100px;
}

.fff-container .timer-svg {
    width: 100%;
    height: 100%;
}

.fff-container .timer-background {
    stroke-width: 4;
    stroke: #6a2a44;
}

.fff-container .timer-progress {
    stroke-width: 4;
    stroke: rgba(255, 215, 0, 0.8);
    stroke-linecap: round;
    stroke-dasharray: 283;
    transition: stroke-dashoffset 1s linear;
    transform: rotate(-90deg);
    transform-origin: center;
}

.fff-container .timer-text {
    font-size: 24px;
    font-weight: bold;
    fill: white;
}

.fff-container .game-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 920px;
}

.fff-container .question-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
}

.fff-container .selected-order-container {
    background-color: rgba(106, 42, 68, 0.7);
    padding: 20px;
    border-radius: 10px;
    width: 40%;
    max-width: 400px;
}

.fff-container .selected-order-content {
    display: flex;
    flex-direction: row; /* Ensure items align in a row */
    align-items: center;
    justify-content: flex-start; /* Align the order to the left */
    gap: 10px; /* Space between the heading and the order items */
}

.fff-container .selected-order-content h3 {
    font-size: 20px;
}

.selected-order {
    display: flex;
    gap: 13px;
}

.order-item {
    width: 26px;
    height: 26px;
    background-color: rgba(255, 215, 0, 0.8);
    border-radius: 50%;
    font-weight: bold;
    color: #59072b;
    font-size: 17px;
    padding-bottom: 3px;
}

.options-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-items: center;
    width: 100%;
}

.fade-in {
    animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.submit-btn {
    padding: 10px 20px;
    font-size: 20px;
    font-weight: bold;
    background-color: #d1b200;
    color: #3b0b24;
    border: none;
    border-radius: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-btn:hover {
    background-color: #e9c600;
    transform: scale(1.1);
}

.submit-btn:disabled {
    cursor: not-allowed;
}

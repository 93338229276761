.hexagon-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease, fill 0.2s ease;
  /* max-width: 200px;  */
  /* width: 100%; */
}

/* Ensure SVG takes full width of the container */
.hexagon-container svg {
  height: auto;
}

/* Initially: Dark red hexagon with a gold gradient border */
.hexagon-container polygon {
  fill: #4f0624; /* Dark red background for the hexagon */
  stroke: url(#gradient);
  stroke-width: 3;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .hexagon-container {
    max-width: 150px; /* Adjust max width for smaller screens */
  }

  /* Responsive text sizes for smaller screens */
  .hexagon-container .round-text {
    font-size: 20px; /* Adjust size */
  }

  .hexagon-container .number-text {
    font-size: 35px; /* Adjust size */
  }
}

@media (max-width: 480px) {
  .hexagon-container {
    max-width: 120px; /* Further adjust max width for very small screens */
  }

  /* Further responsive text sizes for very small screens */
  .hexagon-container .round-text {
    font-size: 24px; /* Adjust size */
  }

  .hexagon-container .number-text {
    font-size: 40px; /* Adjust size */
  }
}

.admin-kbc-container {
	display: flex;
	padding: 20px 50px;
}

/* Column for levels and questions */
.levels-column {
	flex: 2; /* Increase flex to give more width to the left side */
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	max-height: 80vh; /* Limit height */
	overflow-y: auto; /* Enable vertical scrolling */
}

/* Each row contains the level text and the questions */
.level-row {
	/* display: flex; */
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	border-bottom: 2px solid #9b9b9b; /* Add a bottom border */
	padding-bottom: 10px; /* Add some padding below the content */
}

/* Level text styling */
.level-text {
	margin-bottom: 3px;
	flex: 0.35; /* Increase width of the level text column */
	text-align: left;
	font-weight: bold;
	font-size: 25px; /* Increase font size for visibility */
	color: white;
	cursor: pointer;
}

/* Container for the questions */
.questions-row {
	flex: 1;
	display: flex;
	gap: 5px;
	flex-wrap: wrap; /* Allow questions to wrap if too many */
}

/* Question box styling */
.question-box {
	width: 70px;
	padding: 8px;
	text-align: center;
	border: 1px solid #ccc;
	border-radius: 5px;
	cursor: pointer;
	background-color: #f0f0f0;
	transition: background-color 0.3s ease;
}

.question-box:hover {
	background-color: #ddd;
}
.selected {
	width: 70px;
	padding: 8px;
	text-align: center;
	border: 1px solid #ccc;
	border-radius: 5px;
	cursor: pointer;
	background-color: #d4af37;
	transition: background-color 0.3s ease;
}

.question-box.selected {
	background-color: #d4af37;
}

/* Controls column on the right */
.controls-column {
	flex: 0.7; /* Reduce flex to make the right column smaller */
	padding: 10px;
	text-align: center;
	max-height: 80vh;
	overflow-y: auto;
}

.a-button-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
/* Button styling */
.admin-kbc-container .control-button {
	margin: 10px;
	padding: 16px;
	font-size: 16px;
	font-weight: bold;
	color: rgb(255, 255, 255);
	background-color: #d1b200;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.freeze-box {
	margin: 10px;
	padding: 10px;
	font-size: 16px;
	font-weight: bold;
	background-color: #680731;
	border: none;
	border-radius: 5px;
}

.freeze-box .selected .question-box:not(:last-child) {
	margin-right: 10px;
}

.freeze-box .control-button {
	margin: 20px 0 10px 0;
	width: 100%;
}

.admin-kbc-container .control-button:disabled {
	background-color: #ccc;
	cursor: not-allowed;
}

.admin-kbc-container .control-button:hover:not(:disabled) {
	background-color: #e9c600;
}
.admin-kbc-container .controls-heading {
	margin-top: 0;
	color: white;
}

/* General styles for the result card */
.result-kbc-card {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    background-color: #4f0624;
    color: white;
    border: 3px solid #4f0624;
    border-radius: 10px;
    width: calc(100% - 100px);
    height: calc(100vh - 100px);
    margin: 0 auto;
    text-align: center;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    animation: slideInUp 0.3s ease-in-out;
    box-sizing: border-box;
}

/* Header styles */
.result-kbc-header h2 {
    font-size: 40px;
    padding-top: 60px;
    padding-bottom: 100px;
}

.result-kbc-card .points-kbc {
    font-size: 30px;
    padding-bottom: 50px;
}

.result-kbc-card .points-number {
    font-weight: bold;
    font-size: 40px;
    color: #e9c600;
}

.result-kbc-card .reward-kbc {
    font-size: 30px;
    margin-bottom: 20px;
}

/* Home button styles */
.result-kbc-card .home-button {
    background-color: #d1b200;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    color: white;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.result-kbc-card .home-button:hover {
    background-color: #e9c600;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .result-kbc-header h2 {
        font-size: 30px;
    }
    .points-kbc {
        font-size: 25px;
    }
    .reward-kbc {
        font-size: 20px;
    }
    .home-button {
        font-size: 18px;
    }
}

/* Media queries for landscape orientation */
@media (max-height: 500px) and (orientation: landscape) {
    .result-kbc-header h2 {
        font-size: 25px;
    }
    .points-kbc {
        font-size: 18px;
    }
    .reward-kbc {
        font-size: 16px;
    }
    .home-button {
        font-size: 14px;
    }
}

/* Header Container */
.header {
	background-color: #4f0624 !important;
	padding: 15px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	flex-wrap: wrap; /* Allow wrapping of header elements */
}

/* Border for header */
.border {
	border-bottom: 2px solid transparent;
	border-image: radial-gradient(
			circle,
			#ffd700 40%,
			#d4af37 80%,
			#b8860b 100%
		)
		1;
	border-image-slice: 1;
}

button {
	cursor: pointer;
}

/* Left section of the header */
.header-left {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-grow: 1;
	width: auto;
	max-width: 70%;
}

/* Logo styling - dynamic size and margin */
.logo {
	width: 10vw;
	max-width: 50px;
	height: auto;
}

/* Header text styling */
.header-text {
	margin-left: 10px;
	font-size: 2.5vw;
	font-weight: bold;
	background: linear-gradient(
		to bottom,
		rgba(255, 215, 0, 0.2) 0%,
		rgba(255, 215, 0, 1) 50%,
		rgba(255, 215, 0, 0.2) 100%
	);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-family: "Arial", sans-serif;
	letter-spacing: 1px;
	white-space: nowrap;
	flex-shrink: 0;
	width: fit-content;
}

/* Menu icon for mobile */
.menu-icon {
	display: none;
	cursor: pointer;
	margin-left: auto;
}

/* Menu lines for the icon */
.menu-line {
	width: 25px;
	height: 3px;
	background-color: rgb(255, 255, 255);
	margin: 4px 0;
	border-radius: 50px;
}

/* Header buttons */
.header-right {
	display: flex;
	align-items: center;
}

.header-right-logo {
	display: flex;
	align-items: center;
}

/* Button styling */
.logout,
.share-referral {
	background-color: transparent;
	border: 1px solid rgba(255, 215, 0, 0.7);
	height: 40px;
	border-radius: 18px;
	font-size: 18px;
	color: #ffcc00;
	transition: background-color 0.3s ease, transform 0.3s ease;
	margin-left: 20px;
	padding: 5px 15px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

/* Hover effects for buttons */
.logout:hover,
.share-referral:hover {
	background-color: rgba(255, 215, 0, 0.7);
	color: white;
	transform: scale(1.05);
}

/* Responsive Styles */
@media (max-width: 1024px) {
	.header-right {
		display: none;
	}
	.menu-icon {
		display: block;
	}
	.header {
		justify-content: space-between;
	}

	.open {
		padding: 30px 0;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		position: absolute;
		right: 0;
		top: 80px;
		background-color: #4f0624;
		width: 100%;
		z-index: 100;
		/* border-radius: 5px; */
	}
	.open button {
		margin: 10px 10%;
	}
}

@media (max-width: 768px) {
	.header-left {
		max-width: 60%;
	}
	.header-text {
		font-size: 4vw;
	}
}

@media (max-width: 480px) {
	.header-text {
		font-size: 4vw;
		white-space: normal;
		text-align: center;
		flex-basis: 100%;
		margin-left: 0;
	}
	.header-right.open {
		display: flex;
		justify-content: center;
		flex-direction: column;
		position: absolute;
		right: 0;
		top: 71px;
		width: 100%;
	}
	.header-left {
		max-width: 80%;
	}
	.header-right-logo {
		display: none;
	}
}

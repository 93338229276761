.admin-page-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.admin-card {
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	padding: 25px 50px;
	text-align: center;
}

.admin-card h1 {
	color: maroon;
	margin-bottom: 50px;
}

.admin-button-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 50px;
	justify-content: space-around;
	margin: 20px 0;
}

.admin-button {
	padding: 10px 20px;
	font-size: 16px;
	cursor: pointer;
	border: none;
	border-radius: 4px;
	transition: opacity 0.3s;
	color: white;
}

.admin-button:hover {
	opacity: 0.8;
}

.fff-button {
	background-color: #4caf50;
}

.kbc-button {
	background-color: #008cba;
}

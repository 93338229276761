/* Container for the KBC Page */
.kbc-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 100vh;
	box-sizing: border-box;
	background: transparent;
	color: white;
	position: relative;
}

/* Left Section - Main Game */
.main-game {
	width: 70%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: transparent;
	margin-top: 10px;
}

/* Timer Container */
.kbc-container .timer-container {
	position: relative;
	margin-bottom: -6px;
	margin-top: -30px;
}

.kbc-container .timer-svg-container {
	position: relative;
	width: 150px; /* Adjust as necessary */
	height: 75px; /* Adjust as necessary */
}

.kbc-container .semicircle-timer {
	width: 100%;
	height: 100%;
}

.kbc-container .time-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 24px;
	color: white;
}

/* Game Image */
.game-image {
	position: relative;
	width: 350px; /* Larger size to overlay */
	height: 350px;
	margin-top: -50px; /* Move upwards to overlay header */
	margin-bottom: -20px; /* Move downwards to overlap the timer */
	object-fit: contain; /* Maintain aspect ratio */
	z-index: 1; /* Ensure it overlays the header and timer */
	display: block;
	margin-left: auto;
	margin-right: auto;
}

/* Answer Hexagons */
.kbc-container .answers-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10px;
	width: 100%;
	margin-top: 5px;
	margin-bottom: 40px; /* Added more space below the hexagons */
}

.kbc-container .answers-container > div {
	flex: 0 1 calc(50% - 10px);
	display: flex;
	justify-content: center;
}

/* Info Box */
.kbc-container .info-box {
	width: 28%;
	padding: 9px 20px 20px; /* Remove top padding, keep left/right and bottom padding */
	background-color: #4f0624;
	border-left: 3px solid #d4af37;
	text-align: center;
	box-shadow: -1px 10px 15px rgba(0, 0, 0, 0.5);
}

/* Question Line */
.kbc-container .question-line {
	display: flex;
	justify-content: space-between; /* Space elements evenly */
	padding: 5px 0;
	color: white; /* Set all text color to white */
	margin-bottom: 3px;
}

.life-line {
	display: flex; /* Use flex to align children */
	justify-content: center; /* Center children */
	gap: 10px;
	margin-bottom: 10px;
}

.fifty-fifty,
.audience-poll {
	flex: 0 1 100px; /* Set a fixed width for both lifelines */
	height: 25px; /* Set a specific height */
	background: linear-gradient(
		to right,
		#ffd700 0%,
		#d4af37 50%,
		#b8860b 100%
	); /* Gradient background */
	padding: 5px; /* Adjust padding for a smaller appearance */
	margin: 0 5px; /* Add margin between the lifelines */
	border-radius: 5px; /* Optional: Add border-radius for rounded corners */
	color: white; /* Text color */
	font-weight: bold;
	text-align: center; /* Center text */
	font-size: 20px; /* Optional: Adjust font size */
}

.audience-poll path {
	color: rgb(255, 255, 255);
}

/* Set a specific width for spans to align items */
.level-prize {
	width: 101px; /* Adjust this width based on your design */
	text-align: right; /* Center align text within each span */
	margin-right: 10px;
}
.question-line .level-number {
	width: 101px; /* Adjust this width based on your design */
	text-align: center; /* Center align text within each span */
	margin-right: 10px;
}
.question-line .level-points {
	width: 101px; /* Adjust this width based on your design */
	text-align: center; /* Center align text within each span */
	margin-right: 10px;
}

/* Checkpoint Style */
.checkpoint {
	background: linear-gradient(
		to right,
		#b8860b 0%,
		#d4af37 50%,
		#b8860b 100%
	); /* Horizontal gradient with three stops */
	color: black; /* Change text color for better visibility */
}

/* Last Question Style */
.last-question {
	font-weight: bold; /* Make last question text bold */
	background: linear-gradient(
		to right,
		#b8860b 0%,
		#d4af37 50%,
		#b8860b 100%
	); /* Horizontal gradient with three stops */
	color: transparent !important; /* Ensure text is transparent to show background gradient */
	background-clip: text;
	-webkit-background-clip: text;
	font-size: 18px;
}

/* Current Question Border */

.current-question {
	border: 3px solid #d4af37;
}

/* Styling for the current question if it is a checkpoint */
.current-question.checkpoint {
	border: 3px solid #fff;
}

/* Adjustments for responsive design */
@media (max-width: 768px) {
	.info-box {
		width: 90%; /* Adjust width for smaller screens */
	}
}

/* Footer adjustments */
footer {
	margin-top: 50px; /* Adds more space for footer visibility */
}
/* Lifeline used with a crossed-out line */
.lifeline-used,
.lifeline-used path {
	position: relative;
	opacity: 0.6;
	color: grey; /* Dim the text to indicate it's used */
}
.lifeline-used::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	border-radius: 20px;
	height: 2px;
	background-color: red;
	transform: rotate(-16deg); /* Slight diagonal strike */
}

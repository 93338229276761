/* Scrollbar styling */
::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 10px;
}

::-webkit-scrollbar-track {
	background: #4f0624;
	border-radius: 10px;
}

/* Game container with scrolling */
.game1-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	text-align: center;
	box-sizing: border-box;
	color: white;
	overflow-y: auto; /* Enable vertical scrolling */
	height: 78vh; /* Ensure it takes full viewport height */
	max-height: 110vh; /* Prevents it from going beyond the viewport height */
}

.leBhVg,
.loKugq {
	text-align: center !important;
}

.game1-container .answers-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
	justify-items: center;
	margin-top: 30px;
	width: 100%;
	max-width: 920px;
	/* overflow-y: auto; */
}

.game1-container .answers-container .hexagon-game-container {
	width: 100%;
	max-width: 450px;
}

.game1-container .question-container {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	text-align: center;
	width: 100%;
	margin-bottom: 10px;
}

.game1-container .question-container .hexagon-game-container {
	width: 100%;
	max-width: 900px;
}

.timer-container {
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
}

.timer-wrapper {
	position: relative;
	width: 100px;
	height: 100px;
}

.timer-background {
	stroke-width: 4;
	stroke: #6a2a44;
}

.timer-progress {
	stroke-width: 4;
	stroke: rgba(255, 215, 0, 0.8);
	stroke-linecap: round;
	stroke-dasharray: 283;
	transition: stroke-dashoffset 1s linear;
	transform: rotate(-90deg);
	transform-origin: center;
}

.timer-text {
	font-size: 26px;
	font-weight: bold;
	fill: white;
}

.timer-label {
	font-size: 15px;
	fill: white;
}

.round-info {
	margin-top: 12px;
	margin-bottom: 30px;
}

.quiz-text .info-title {
	font-size: 20px;
	color: white;
}

.quiz-text .info-content {
	font-size: 22px;
	font-weight: bold;
	color: rgb(255, 255, 255);
}

.next-question-container {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.next-question-btn {
	background-color: #59072b; /* Same background color as ResultCard */
	border: 3px solid rgba(255, 215, 0, 0.8); /* Same border as ResultCard */
	border-radius: 50%; /* Keep the circular shape */
	padding: 15px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color 0.3s ease;
	color: white; /* White arrow icon */
	font-size: 24px;
}

.next-question-btn:hover {
	background-color: #8c123a; /* Darker hover effect for the button */
}

.next-arrow-icon {
	color: white; /* Ensure the arrow icon is white */
}

@media (max-width: 820px) {
	.game1-container .answers-container {
		grid-template-columns: 1fr;
	}
	.game1-container {
		overflow-y: auto;
		height: 85vh; /* Adjust for small screens */
	}
	.answers-container .hexagon-game-container,
	.question-container .hexagon-game-container {
		max-width: 100%;
	}

	.question-container .hexagon-game-container {
		font-size: 16px;
	}
}

/* Landscape-specific styles */
@media (orientation: landscape) and (max-height: 450px) {
	.game1-container {
		padding: 10px; /* Reduce padding in landscape */
		overflow-y: auto; /* Enable scrolling in landscape */
		height: 66vh; /* Adjust height */
	}

	.answers-container {
		display: grid; /* Use grid display for answers */
		grid-template-columns: 1fr; /* Single column layout */
		gap: 10px; /* Space between hexagons */
		width: 100%; /* Use a percentage width for better responsiveness */
		max-width: 800px; /* Reduced max-width for answers container */
		margin: 0 auto; /* Center the container */
	}

	.answers-container .hexagon-game-container,
	.question-container .hexagon-game-container {
		width: 100%; /* Full width for each hexagon */
	}
}

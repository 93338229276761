.admin-fff-container {
	max-width: 40%; /* Limit the width of the admin container */
	margin: 10px auto; /* Center the container */
	padding: 15px;
	border: 1px solid #ccc;
	border-radius: 8px;
	box-shadow: 0 4px 10px #0000001a;
	background-color: #f9f9f9;
	text-align: center;
}

.admin-fff-heading {
	font-size: 2rem;
	text-align: center;
	color: #e0b90a;
}

.question-select {
	width: 100%;
	border-radius: 5px;
	border: 1px solid #ddd;
	font-size: 16px;
}

.question-button-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	margin-bottom: 10px;
}

.question-button {
	padding: 10px;
	font-size: 16px;
	color: white;
	background-color: #6d0934;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	width: 80px; /* Set button width */
	margin: 10px;
}

.question-button[disabled] {
	background-color: #ccc; /* Disabled button color */
	cursor: not-allowed;
}

.question-button:hover:not([disabled]) {
	background-color: #8d0540; /* Darker color on hover */
}

/* Hover tooltip (uses title attribute) */
.question-button:hover::after {
	content: attr(title);
	position: absolute;
	background-color: rgba(0, 0, 0, 0.7);
	color: white;
	padding: 5px;
	border-radius: 5px;
	top: -30px;
	left: 50%;
	transform: translateX(-50%);
	font-size: 0.9rem;
	white-space: nowrap;
}

.button-container {
	display: flex;
	flex-direction: column;
	gap: 20px; /* Space between buttons */
}

.control-button {
	padding: 10px;
	font-size: 16px;
	color: white;
	background-color: #d1b200;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.control-button:disabled {
	background-color: #ccc; /* Disabled button color */
	cursor: not-allowed; /* Change cursor for disabled state */
}

.control-button:hover:not(:disabled) {
	background-color: #e9c600; /* Darker color on hover */
}

.cards-container {
	display: flex;
	justify-content: center; /* Center the cards horizontally */
	align-items: flex-start; /* Align cards to the start */
	gap: 20px; /* Space between cards */
	width: 80%; /* Use full width of the parent container */
	margin: 20px auto 0; /* Center the cards and add top margin */
}

.options-card,
.results-card {
	flex: 0 0 40%; /* Each card occupies 40% of the cards-container */
	padding: 20px;
	border: 1px solid #ccc; /* Optional: Add a border */
	border-radius: 8px; /* Optional: Add rounded corners */
	background-color: #e9f9e9; /* Light background for cards */
	height: 220px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.option-item {
	padding-left: 20px;
	text-align: left;
}

.results-card {
	background-color: #e9f9e9; /* Light background for results */
}
.admin-fff-card-heading {
	text-align: center;
	margin-top: 0;
}
.results-card .result-item {
	font-size: 18px;
	display: flex;
	justify-content: space-between; /* Ensures name is left-aligned and time is right-aligned */
	width: 90%; /* Ensures the item takes up the full width */
}

.results-card .result-item.winner {
	background-color: green; /* Winner in gold */
	color: white !important;
	border-radius: 20px;
	padding: 0 10px;
}
.results-card .result-item.correct {
	color: green; /* Correct answers in green */
}

.results-card .result-item.wrong {
	color: red; /* Wrong answers in red */
}

/* Time alignment */
.result-item .time {
	margin-left: auto; /* Pushes time to the right */
	text-align: right;
}

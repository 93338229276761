/* styles for FFF ResultCard */
.fff-result-card {
    width: 60%;
    height: calc(100vh - 100px);
    padding: 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.fff-result-card .heading-result {
    font-size: 3rem;
    letter-spacing: 3px;
    text-align: center;
    margin-top: 0;
    text-shadow: 2px 3px 5px #e9c600;
    margin-bottom: 20px;
}

.fff-result-card .fff-waiting-message {
    font-size: 1.8rem;
    color: #fff;
    text-align: center;
}

.fff-result-card .fff-result-item {
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.3rem;
}

.fff-result-card .correct {
    background-color: #28a745;
}

.fff-result-card .incorrect {
    background-color: #dc3545;
}

.fff-result-card .winner {
    background-color: #218838;
    font-weight: bold;
}

.fff-result-card .order-text {
    font-size: 1.5rem;
    color: #fff;
}

.fff-result-card .tab-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.fff-result-card .tab-buttons button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #6d0934;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 5px;
    transition: background-color 0.3s ease;
}

.fff-result-card .tab-buttons button.active {
    background-color: #8d0540;
}

.fff-result-card .tab-buttons button:hover {
    background-color: #8d0540;
}

.fff-result-card .answers-container {
    margin-top: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.fff-result-card .question-container {
    background-color: #6d0934;
    border: 2px solid #fff;
    border-radius: 25px;
    padding: 15px 30px;
    margin-bottom: 20px;
    text-align: center;
}

.fff-result-card .question-fff {
    font-size: 1.3rem;
    margin: 0;
}

.fff-result-card .options-fff {
    border-radius: 15px;
    padding: 15px;
    width: 60%; /* Reduced width */
    display: flex;
    flex-direction: column;
    gap: 15px; /* Space between individual option divs */
}

.fff-result-card .option-div {
    background-color: #6d0934;
    border: 2px solid #fff;
    border-radius: 50px;
    padding: 10px;
	padding-left: 20px;
}

.fff-result-card .options-fff p {
    margin: 0;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
}

.fff-result-card .option-letter {
    color: gold;
    font-weight: bold;
    margin-right: 10px;
    width: 20px;
    display: inline-block;
}
